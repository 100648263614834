import {SenecaResponse} from "atfcore-commonclasses";
import {IApplicationScope} from "./applicationScope";

module MainApp {
    var app = angular.module("app");

    export interface IMainAppControllerScope extends IApplicationScope {
        notificationCount: number;
    }

    export class MainAppController {
        ctrl: MainAppController;

        static $inject = ["$scope", "$rootScope", "$translate", "tmhDynamicLocale", "amMoment", "moment", "$sessionStorage", "GlobalApplicationData", "jwtHelper", "$uibModal", "$timeout", "UserService", "$location", "NotificationService", "$interval", "UtilService"];

        constructor(protected $scope: IMainAppControllerScope,
            protected $rootScope: ng.IScope,
            protected $translate: angular.translate.ITranslateService,
            protected tmhDynamicLocale: any,
            protected amMoment: any,
            protected moment: any,
            protected $sessionStorage: any,
            protected GlobalApplicationData: any,
            protected jwtHelper: any,
            protected $uibModal: angular.ui.bootstrap.IModalService,
            protected $timeout: angular.ITimeoutService,
            protected UserService: any,
            protected $location: angular.ILocationService,
            protected NotificationService: any,
            protected $interval: any,
            protected UtilService: any
        ) {

            this.$scope.setJWT = this.setJWT;
            this.$scope.cleanJWT = this.cleanJWT;
            this.$scope.setLocale = this.setLocale;
            this.$scope.getNotificationCount = this.getNotificationCount;
            this.$scope.renewToken = this.renewToken;
            this.$scope.setUserGroups = this.setUserGroups;
            this.$scope.getBgacademyIndicators = this.getBgacademyIndicators;
            this.$scope.openStandardErrorDialog = this.openStandardErrorDialog;

            this.$scope.notificationCount = 0;

            this.$scope.bgacademyIndicators = {};

            // Lingua corrente e timezone recuperata dal Token
            this.$scope.geti18nLang = this.geti18nLang;
            this.$scope.getUserTimezone = this.getUserTimezone;

            // Lingua di default
            this.$scope.geti18nDefaultLang = this.geti18nDefaultLang;

            let self = this;
            let cleanUpErrorModalEvent = this.$rootScope.$on('showApplicationModalErrors', (event: any, args: any) => {
                self.$scope.openStandardErrorDialog(args);
            });

            // Mi metto in ascolto dell'evento che forza un refresh delle notifiche
            let cleanUpNotificationRefreshEvent = this.$rootScope.$on('notificationsRefresh', (event: any, args: any) => {
                self.getNotificationCount(true);
            });

            // Mi metto in ascolto dell'evento che forza un refresh del rinnovo del token
            let cleanUpRenewTokenRefreshEvent = this.$rootScope.$on('tokenRefresh', (event: any, args: any) => {
                self.renewToken();
            });

            let cleanUpBgacademyIndicatorsRefreshEvent = this.$rootScope.$on('bgacademyIndicatorsRefresh', (event: any, args: any) => {
                self.getBgacademyIndicators();
            });

            this.$scope.$on('$destroy', () => {
                cleanUpNotificationRefreshEvent();
                cleanUpBgacademyIndicatorsRefreshEvent();
                cleanUpErrorModalEvent();
                cleanUpRenewTokenRefreshEvent();
            });

            // Scrolla la pagina finchè l'elemento non è in testa
            this.$scope.scrollElementToTop = this.scrollElementToTop;
        }

        public setUserGroups = () => {
            // Recupero i gruppi dell'utente loggato, dato che non sono più inclusi all'interno del token jwt
            this.UserService.getGroupsOfUser.get({})
                .$promise
                .then((groupsResponse: SenecaResponse<any>) => {
                    if (groupsResponse.error) {
                        // Non faccio niente. Tengo i gruppi recuperati precedentemente
                    } else {
                        // Salvo i dati dei gruppi in sessione
                        this.GlobalApplicationData.userGroups = groupsResponse.response;
                    }
                })
        }

        public renewToken = () => {
            // Chiedo subito un altro token poiché al refresh della pagina (dopo il quale passerò sicuramente in questo metodo) il vecchio intervallo si cancella, facendo passare troppo tempo e rischiando di far scadere la sessione, dato che il token è valido solo per due ore
            this.UserService.renewToken.query({
            })
                .$promise
                .then((data: SenecaResponse<string>) => {
                    // Se non ho il Token segnalo un errore
                    if (data.error) {
                        // Non faccio niente. Il token resta quello di prima
                    } else {
                        // Aggiorno il token nella sessione locale del browser
                        this.$sessionStorage.identityToken = data.response;
                        this.GlobalApplicationData.identityToken = data.response;
                        this.GlobalApplicationData.jwtPayload = this.jwtHelper.decodeToken(data.response);
                    }
                    this.setUserGroups();
                })

            // Se esiste un timer precedente per il rinnovo del token, lo annullo
            if (this.GlobalApplicationData.tokenRenewalTimer) {
                this.$interval.cancel(this.GlobalApplicationData.tokenRenewalTimer);
            }
            this.GlobalApplicationData.tokenRenewalTimer = this.$interval(() => {
                this.UserService.renewToken.query({
                })
                    .$promise
                    .then((data: SenecaResponse<string>) => {
                        // Se non ho il Token segnalo un errore
                        if (data.error) {
                            // Non faccio niente. Il token resta quello di prima
                        } else {
                            // Aggiorno il token nella sessione locale del browser
                            this.$sessionStorage.identityToken = data.response;
                            this.GlobalApplicationData.identityToken = data.response;
                            this.GlobalApplicationData.jwtPayload = this.jwtHelper.decodeToken(data.response);
                        }
                        this.setUserGroups();
                    })
            }, this.GlobalApplicationData.tokenRenewalMillis);
        }

        // Salva il Token in locale e nell'oggetto principale dell'applicativo
        public setJWT = (token: string) => {
            this.$sessionStorage.identityToken = token;
            this.GlobalApplicationData.identityToken = token;
            this.GlobalApplicationData.jwtPayload = this.jwtHelper.decodeToken(token);
            this.setUserGroups();

            // Inizio intervallando i rinnovi del token
            this.renewToken();
        };

        // Cerco le notifiche da mostrare nell'header, utilizzando un polling continuo
        public getNotificationCount = (firstTime?: any) => {
            if (this.GlobalApplicationData.identityToken) {
                if (this.GlobalApplicationData.notificationsRenewalTimer) {
                    this.$interval.cancel(this.GlobalApplicationData.notificationsRenewalTimer);
                }
                // Se è la prima volta, avvio immediatamente il recupero delle notifiche
                if (firstTime) {
                    this.NotificationService.notificationCount.query({
                    }).$promise
                        .then((res: SenecaResponse<any>) => {
                            if (res.response) {
                                this.$scope.notificationCount = res.response;
                            }
                        })
                }

                // Poi setto l'intervallo per recuperare le notifiche aggiornate
                this.GlobalApplicationData.notificationsRenewalTimer = this.$interval(() => {
                    this.NotificationService.notificationCount.query({
                        fromRecord: 0,
                        numRecords: 1000,
                        getOnlyUnread: true
                    }).$promise
                        .then((res: SenecaResponse<any>) => {
                            if (res.response) {
                                this.$scope.notificationCount = res.response;
                            }
                        })
                }, this.GlobalApplicationData.notificationsRenewalMillis);
            };
        }

        public getBgacademyIndicators = () => {
            if (this.GlobalApplicationData.identityToken) {
                // Se è la prima volta, avvio immediatamente il recupero degli indicatori
                this.UtilService.getAllCounters.query({
                    newsIgnoreVisibility: false
                }).$promise
                    .then((senecaResponse: SenecaResponse<any>) => {
                        if (senecaResponse.response) {
                            // Non posso sostituire l'oggetto, perché viene mappato in binding, quindi cancello tutti gli attributi e li ricreo
                            for (let key in this.$scope.bgacademyIndicators) {
                                if (this.$scope.bgacademyIndicators.hasOwnProperty(key)) {
                                    delete this.$scope.bgacademyIndicators[key];
                                }
                            }
                            for (let key in senecaResponse.response) {
                                if (senecaResponse.response.hasOwnProperty(key)) {
                                    this.$scope.bgacademyIndicators[key] = senecaResponse.response[key];
                                }
                            }
                        }
                    })
            }
        }

        // Elimina il Token
        public cleanJWT = () => {
            this.$sessionStorage.identityToken = null;
            this.GlobalApplicationData.identityToken = null;
            this.GlobalApplicationData.jwtPayload = null;
            this.GlobalApplicationData.userGroups = null;
            // Se esiste un timer precedente per il rinnovo del token, lo annullo
            if (this.GlobalApplicationData.tokenRenewalTimer) {
                this.$interval.cancel(this.GlobalApplicationData.tokenRenewalTimer);
            }
        }

        // Gestisce il cambio lingua
        public setLocale = (langKey: string, localeKey: string) => {
            // Se mi sono arrivati i dati li aggiorno
            if (langKey) {
                // Metodo del plugin che si occupa si impostare la lingua passata
                this.$translate.use(langKey);
                // Uso il servizio specifico anche per caricare il file standard di angular del locale
                this.tmhDynamicLocale.set(langKey);

                // Imposto anche il locale ed il timezone per la libreria moment.js, in modo che le date create con moment(data) siano localizzate correttamente
                this.amMoment.changeLocale(langKey);
                this.moment.tz.setDefault("UTC");
                //moment.tz.setDefault(GlobalApplicationData.selectedTimeZoneKey.replace(' ', '_'));
            }
        };

        // Scrolla l'elemento fino in testa alla pagina
        public scrollElementToTop = ($event: any) => {
            if (navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i)) {
                $(window).scrollTop($($event.currentTarget).offset().top);
            }
        }

        /**
         * Questa finestra modale si aspetta che con l'evento sia passato un array composto da
         * oggetti con due attributi: 'severity' e 'code' più un terzo opzionale 'message'
         * 'severity' può essere: 'success', 'info', 'warning', 'danger'.
         * 'code' è il numero dell'eccezione che sarà associata alla traduzione prefissata da "sqlMessage."
         * 'message' è un opzionale testo aggiuntivo che arriva dal server e deve essere già decodificato in lingua.
         * 'hideUnknown' è un opzionale booleano che nasconde il testo di errore sconosciuto nel caso si veglia mostrare solamente il testo aggiuntivo
         * Es:
         var errors = [];
        errors.push({severity: 'success', code: 0, message: 'Testo di success'});
        errors.push({severity: 'info', code: 21351, message: 'Testo di info'});
        errors.push({severity: 'warning', code: 222, message: 'Testo di warning'});
        errors.push({severity: 'danger', code: 3135, message: 'Testo di errore'});
        $rootScope.$emit('showApplicationModalErrors', errors);
        */
        public openStandardErrorDialog = (errors: any) => {
            let self = this;
            let modalInstance = this.$uibModal.open({
                backdrop: "static",
                size: 'md',
                templateUrl: 'errors-modal.html',
                controller: 'ErrorsModalInstanceController as ctrl',
                resolve: {
                    errors: () => {
                        return errors;
                    },
                    $translate: () => {
                        return this.$translate;
                    }
                }

            });

            // Ritorno la promessa della risposta dell'utente
            return modalInstance.result;
        }

        // Lingua attualmente in uso recuperata dal Token
        public geti18nLang = () => {
            return this.GlobalApplicationData.jwtPayload && this.GlobalApplicationData.jwtPayload.user && this.GlobalApplicationData.jwtPayload.user && this.GlobalApplicationData.jwtPayload.user.userOptions.langCode ? this.GlobalApplicationData.jwtPayload.user.userOptions.langCode.substring(0, 2) : "it";
        }

        // Lingua attualmente in uso recuperata dal Token
        public getUserTimezone = () => {
            return this.GlobalApplicationData.jwtPayload && this.GlobalApplicationData.jwtPayload.user && this.GlobalApplicationData.jwtPayload.user && this.GlobalApplicationData.jwtPayload.user.userOptions.timezone ? this.GlobalApplicationData.jwtPayload.user.userOptions.timezone : "Europe/Rome";
        }

        // La lingua di default
        public geti18nDefaultLang = () => {
            for (var i = 0; i < this.GlobalApplicationData.langs.length; i++) {
                if (this.GlobalApplicationData.langs[i].mandatory) {
                    return this.GlobalApplicationData.langs[i].lang.substring(0, 2);
                }
            }
            return "it";
        }

    }
    app.controller("MainAppController", MainApp.MainAppController);
}

/**
 * Controller della modale per gli errori applicativi
 */

module ErrorsModalInstance {
    var app = angular.module("app");

    export interface IErrorsModalInstanceControllerScope extends ng.IScope {
        ok: Function;
        errors: any;
    }

    export class ErrorsModalInstanceController {
        ctrl: ErrorsModalInstanceController;

        static $inject = ["$scope", "$uibModalInstance", "$translate", "errors"];

        constructor(protected $scope: IErrorsModalInstanceControllerScope,
            protected $uibModalInstance: angular.ui.bootstrap.IModalServiceInstance,
            protected $translate: angular.translate.ITranslateService,
            protected errors: any
        ) {
            $scope.errors = errors;

            this.$scope.ok = this.ok;

            angular.forEach($scope.errors, (e) => {
                if (e.code) {
                    e.decoded = $translate.instant('sqlMessages.' + e.code);
                    if (e.decoded === 'sqlMessages.' + e.code) {
                        e.decoded = $translate.instant('sqlMessages.UNEXPECTED_ERROR') + " (cod. " + e.code + ")";
                    }
                }
                else {
                    if (!e.hideUnknown) {
                        e.decoded = $translate.instant('sqlMessages.UNKNON_ERROR');
                    }
                }
            });
        }

        public ok = () => {
            this.$uibModalInstance.close();
        }

    }
    app.controller("ErrorsModalInstanceController", ErrorsModalInstance.ErrorsModalInstanceController);
}